<template>
	<div class="login">
		<div class="login_inp">
			<div class="top_title">
				<img class="uimg" src="../../assets/logo.png" srcset="">
				<span class="logo">登录</span>
			</div>
			<div class="from">
				<div class="zhanghao">
					<div class="a iconfont icon-zhanghao"></div>
					<input class="inp" v-model="username" placeholder="请输入你的账号" type="text">
				</div>
				<div class="zhanghao">
					<div class="a iconfont icon-mima1"></div>
					<input class="inp" v-model="pwd" placeholder="请输入你的密码" type="password">
				</div>
				<div class="check">
					<el-checkbox>记住密码</el-checkbox>
				</div>
			</div>
			<div class="btn" @click="login">登录</div>
		</div>
	</div>
</template>

<script>
	import axios from "../../common/axios";
	export default {
		data() {
			return {
				username: "",
				pwd: '',
			}
		},
		methods: {
			login() {
				var to_time = Math.round(new Date() / 1000).toString();
				// var memberInfo = JSON.stringify({
				// 	Access_Control_time: to_time
				// });
				// let postheader = {
				// 	"Access-Control-tion": memberInfo,
				// };

				let user = this.username;
				// console.log(this.pwd);
				let pwd = this.$encrypt(this.$encrypt(this.pwd) + to_time);
				let url = "/adminshop/login/index.html";
				let data = {
					user,
					pwd
				}
				axios.post(url,data,to_time).then((res) => {
					let data = res.data
					if (res.success) {
						// console.log(data)
						if (data.status == 1) {
							let token = data.token
							let id = data.user_id
							localStorage.setItem("token", token)
							localStorage.setItem("user_id", id)
							this.$message({
								message: data.msg,
								type: 'success'
							});
							setTimeout(() => {
								this.$emit("on_login", true)
							}, 1200)
						} else {
							this.$alert(data.msg, {
								title: '提示',
								confirmButtonText: '确定',
								type: 'error'
							})
						}
					}

				})
			}
		}
	}
</script>

<style>
	@import "./login.css";
</style>
