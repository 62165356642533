import Vue from 'vue'
import VueRouter from 'vue-router'

const GoodsIndex =()=>import('../components/goods_index/goods_index')
const Goods =()=>import('../components/goods/goods')
const Transaction =()=>import('../components/transaction/transaction')
const Shop =()=>import('../components/shop/shop')
const Goodstype =()=>import('../components/goodstype/index')
const UserList =()=>import('../components/user_list/user_list')
const Shop_information =()=>import('../components/shop_information/shop_information.vue')
const Market_activity =()=>import('../components/Market_activity/Market_activity.vue')
const Account =()=>import('../components/account/account.vue')
const SelectClass =()=>import('../components/select_class/select_class.vue')
const Mygoods =()=>import('../components/mygoods/mygoods.vue')
const Commodity_audit =()=>import('../components/commodity_audit/commodity_audit.vue')
const Goods_list =()=>import('../components/goods_list/goods_list.vue')
const Edit_goods =()=>import('../components/edit_goods/select_class.vue')
const Renovation =()=>import('../components/renovation/renovation.vue')



Vue.use(VueRouter)
const routes = [
    {
        path:'/',
        redirect:"goods_index"
    },
    {
        path:'/goods_index',
        component:GoodsIndex
    },
    {
        path:'/goods',
        component:Goods,
        // redirect:"",
        children:[
            {
                path:'/',
                redirect:'select_class'
            },
            {
                path:'select_class',
                component:SelectClass
            },
            {
                path:'mygoods',
                component:Mygoods,
            },
            {
                path:'goods_list',
                component:Goods_list
            },
            {
                path:'commodity_audit',
                component:Commodity_audit
            },
            {
                path:'edit_goods',
                component:Edit_goods
            }
        ]

    },
    {
        path:'/transaction',
        component:Transaction
    },
    {
        path:'/shop',
        component:Shop,
        redirect:"shop_information",
        children:[
            {
                path:'/',
                redirect:"shop_information"
            },        
            {
                path:'shop_information',
                component:Shop_information
            }, 
            {
                path:'Market_activity',
                component:Market_activity
            },
            {
                path:'Account',
                component:Account
            },
            {
                path:'Renovation',
                component:Renovation
            },
        ]
    },
    {
        path:'/goodstype',
        component:Goodstype
    },
    {
        path:'/user_list',
        component:UserList
    },
]
const router = new VueRouter({
    routes
})



export default router