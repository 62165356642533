// const websiteUrl = 'http://192.168.0.108';  //本地
// const websocketUrl = 'ws://192.168.0.108:8282';

// const websiteUrl = 'https://cs.zd1379.cn';
// const websocketUrl = 'wss://cs.zd1379.cn:8282/wss';

// const websiteUrl = 'https://cs.ycsion.cn';
// const websocketUrl = 'wss://cs.ycsion.cn:8282/wss';

const websiteUrl = 'https://test.ycsion.cn';
const websocketUrl = 'wss://test.ycsion.cn:8282/wss';
export default {  
    websiteUrl,
	websocketUrl
}