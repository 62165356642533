<template>
	<div class="content" id="content">
		<div class="left">
			<div class="left_title">
				<img class="uimg" src="../../assets/logo.png" srcset="" />
				<span class="logo">商城管理</span>
			</div>
			<div class="left_tab">
				<div class="tab_box" :class="{ active: patharr.indexOf('goods_index') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/goods_index" class="tab_item iconfont icon-dapingshouye">
						<span class="tab_title">首页</span>
					</router-link>
				</div>
				<div class="tab_box" :class="{ active: patharr.indexOf('goodstype') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/goodstype" class="tab_item iconfont icon-type">
						<span class="tab_title">类型</span>
					</router-link>
				</div>
				<div class="tab_box" :class="{ active: patharr.indexOf('goods') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/goods" class="tab_item iconfont icon-shangpin">
						<span class="tab_title">商品</span>
					</router-link>
				</div>
				<div class="tab_box" :class="{ active: patharr.indexOf('transaction') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/transaction" class="tab_item iconfont icon-jiaoyijilu">
						<span class="tab_title">交易</span>
					</router-link>
				</div>

				<div class="tab_box" :class="{ active: patharr.indexOf('shop') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/shop" class="tab_item iconfont icon-dianpu">
						<span class="tab_title">店铺</span>
					</router-link>
				</div>
				<div class="tab_box" :class="{ active: patharr.indexOf('user_list') >= 0 }">
					<div class="tab_item_bg">
						<div class="bg_ten">
							<div class="bg_ten_box"></div>
						</div>
						<div class="bg_tbox">
							<div class="bg_tbox_kong"></div>
							<div class="bg_tbox_com"></div>
						</div>
						<div class="bg_ten bg_ten_rig">
							<div class="bg_ten_box"></div>
						</div>
					</div>
					<router-link to="/user_list" class="tab_item iconfont icon-zhanghao">
						<span class="tab_title">用户</span>
					</router-link>
				</div>
			</div>
		</div>
		<div class="right">
			<div class="right_head">
				<div class="right_head_kong"></div>
				<div class="shuaxin iconfont icon-shuaxin" @click="refresh"></div>
				<div class="right_head_tpi"></div>
				<div class="topheadimg">
					<img class="uimg" :src="headimg" alt="" srcset="">
				</div>
				<el-row class="block-col-2">
					<el-col :span="12">
						<el-dropdown @command="handleCommand">
							<span class="head_title">{{nickname}}<span
									class="head_icon iconfont icon-shangla"></span></span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="0" icon="iconfont icon-zhanghao">账号信息</el-dropdown-item>
								<el-dropdown-item command="1" icon="iconfont icon-dianpu">店铺信息</el-dropdown-item>
								<el-dropdown-item command="2">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</el-col>
				</el-row>
			</div>
			<div class="right_content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "../../common/axios";
	export default {
		components: {},
		data() {
			return {
				tab_inx: 0,
				patharr: [],
				headimg: "",
				nickname: "",
			};
		},
		created() {
			this.on_tab();
		},
		watch: {
			$route: "routerChange",
		},
		mounted() {
			let that = this;
			this.$bus.on("lowerRight", (value) => {
				that.patharr = value;
			});
			this.get_user();
		},

		methods: {
			get_user() {
				// console.log(1);
				let url = "/adminshop/login/get_user.html"

				axios.post(url).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {

						if (data.status == 1) {
							this.headimg = data.headimg;
							this.nickname = data.nickname;
						}
					} else {
						console.log(res.error);
					}
				});
			},
			routerChange(to) {
				let topath = to.path;
				let patharr = topath.split("/");
				this.patharr = patharr;
				// console.log(from);
			},
			on_tab() {
				let path = this.$route.path;
				let patharr = path.split("/");
				this.patharr = patharr;
			},
			//点击下拉菜单的子项
			handleCommand(command) {
				//跳转账号信息
				if (command == "0") {
					this.$router.push({
						path: "/shop/Account",
					});
				}
				//跳转店铺信息
				if (command == "1") {
					this.$router.push({
						path: "/shop",
					});
				}

				if (command == "2") {
					window.localStorage.clear();
					window.location.reload();
				}
			},
			refresh() {
				window.location.reload();

			}
		},
	};
</script>

<style>
	.ql-container {
		height: 300px !important;
	}

	@import "../../static/css/index/index.css";
	@import "../../static/css/common/index.css";
	@import "../../static/iconfont/iconfont.css";
</style>
