import Vue from 'vue'
import App from './App.vue'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'

import router from './router/index'
import VueBus from 'vue-bus'

//富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import * as Quill from 'quill'  // 引入编辑器
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

import encrypt from "js-md5"

// const hub = new Vue()

Vue.use(ElementUI);
Vue.use(VueBus)
Vue.use(VueQuillEditor)//富文本编辑器
Vue.prototype.$encrypt = encrypt

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
