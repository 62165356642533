import axios from 'axios'
import helper from "./helper";
import encrypt from "js-md5"
export default{
    post(url,edata={},isto_time=""){
        return new Promise((resolve)=>{
            if (!(url.indexOf("http://") === 0 || url.indexOf("https://") === 0)) {
				url = helper.websiteUrl + url;
			}

            let user_id = localStorage.getItem("user_id");
            let user_token = localStorage.getItem("token");
            var to_time = Math.round(new Date() / 1000).toString();
           
            let postheader = {}
            if(user_id!=undefined && user_id!=null && user_id!='' && user_token!=undefined && user_token!=null && user_token!='' ){
				postheader['Access-Control-tion'] = JSON.stringify({
					"Access_Control_id": user_id,
					"Access_Control_time": to_time,
					"Access_Control_info": encrypt(user_token + to_time.toString())
				});
			}
            if(isto_time!=""){
                postheader['Access-Control-tion'] = JSON.stringify({
					"Access_Control_time": isto_time,
				});
            }
            let success=false;
            // axios.post(url,edata,{headers: postheader}).then((res)=>{
            axios({
                headers: postheader,
                url: url,
                method: "post",
                data: edata
            }).then((res)=>{
                success = true;
                resolve({"success":success,'data':res.data});
            }).catch((error)=>{
                this.$alert('Network error', 'error', {
                    confirmButtonText: 'confirm',
                    callback: () => {
                        resolve({"success":success,'error':error,data:{msg:'Network error'}});
                    }
                });
            })
        });
    },
    get(url,edata={}){
        return new Promise((resolve)=>{
            if (!(url.indexOf("http://") === 0 || url.indexOf("https://") === 0)) {
				url = helper.websiteUrl + url;
			}

            let user_id = localStorage.getItem("user_id");
            let user_token = localStorage.getItem("token");
            var to_time = Math.round(new Date() / 1000).toString();
           
            let postheader = {}
            if(user_id!=undefined && user_id!=null && user_id!='' && user_token!=undefined && user_token!=null && user_token!='' ){
				postheader['Access-Control-tion'] = JSON.stringify({
					"Access_Control_id": user_id,
					"Access_Control_time": to_time,
					"Access_Control_info": encrypt(user_token + to_time.toString())
				});
			}
            let success=false;
            axios({
                headers: postheader,
                url: url,
                method: "get",
                data: edata,
            }).then((res)=>{
                success = true;
                resolve({"success":success,'data':res.data});
            }).catch((error)=>{
                this.$alert('Network error', 'error', {
                    confirmButtonText: 'confirm',
                    callback: () => {
                        resolve({"success":success,'error':error,data:{msg:'Network error'}});
                    }
                });
            })
        });
    },
    ajax(params){
        return new Promise((resolve)=>{
            let success=false;
            let method = params['method'];
            if(method==undefined || method=="" || method==null){
                params.method = 'get';
            }
            axios(params).then((res)=>{
                success = true;
                resolve({"success":success,'data':res.data});
            }).catch((error)=>{
                resolve({"success":success,'error':error,data:{msg:'Network error'}});
            })
        });
    }
} 