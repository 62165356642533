<template>
  <div class="content" id="content">
    <Index />
    <div class="login" v-if="!islogin">
      <Login @on_login="on_login" />
    </div>
  </div>
</template>

<script>
import Index from "./components/index/index.vue";
import Login from "./components/login/login.vue";
import axios from "./common/axios";

export default {
  components: {
    Index,
    Login,
  },
  data() {
    return {
      islogin: false, //是否登录
      nickname:"",
      phone:"",
      user:"",
      email:"",
      headimg:"",
      login_time:"",
    };
  },
  created() {
    let userid = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");
    if(userid>0 && token!=""){
      this.islogin = true;
    }
    this.onshow();
  },
  methods: {
    //登录主键传的值
    on_login(islogin) {
      this.islogin = islogin;
      this.$router.push({ path: "/goods_index" });
    },
    onshow() {
      let url =  "/adminshop/login/token.html"
      axios.post(url).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          // console.log(data);
          if(data.status==1){
            this.islogin=true;
          }else{
            this.islogin=false;
          }
        } else {
          console.log(res.error);
        }
      });
    },
  },
};
</script>

<style >
@import "./static/css/common/index.css";
.ql-container {
  height: 300px !important;
}
</style>
